import React, { Component } from "react";
import PropTypes from "prop-types";
// import { scrambleRef } from "~utils/scrambler";

class ScrollSelectComponent extends Component {
  state = {
    active: false,
    intervals: []
  };

  componentRef = React.createRef();

  defaultSelectRef = React.createRef();

  scrollableOptions = [];

  componentDidMount() {
    document.addEventListener(`mousedown`, this.handleDocumentMousedown, false);
    document.addEventListener(`keydown`, this.handleKeyPress, false);
  }

  componentWillUnmount() {
    this.clearIntervals();

    document.removeEventListener(
      `mousedown`,
      this.handleDocumentMousedown,
      false
    );
    document.removeEventListener(`keydown`, this.handleKeyPress, false);
  }

  //

  handleKeyPress = e => {
    if (e.keyCode === 27 && this.state.active) {
      this.reset();
    }
  };

  handleDocumentMousedown = e => {
    if (
      this.componentRef &&
      this.componentRef.current &&
      !this.componentRef.current.contains(e.target) &&
      this.state.active
    ) {
      this.reset();
    }
  };

  //

  clearIntervals = () => {
    if (!this.state.intervals.length) {
      return;
    }

    this.state.intervals.forEach(interval => {
      clearInterval(interval);
    });

    this.setState({
      intervals: []
    });
  };

  getOptionJSX = () => {
    const jsx = [];

    if (!this.props.options || !this.props.options.length) {
      return jsx;
    }

    if (this.scrollableOptions.length === 0) {
      for (let i = 0; i < this.props.options.length; i += 1) {
        const optionRef = React.createRef();

        this.scrollableOptions.push(optionRef);
      }
    }

    this.props.options.forEach((option, index) => {
      const optionIndex = index;

      jsx.push(
        <button
          ref={this.scrollableOptions[optionIndex]}
          key={`option-${optionIndex}`}
          type="button"
          className={`scroll-select__button w-full relative block cursor-pointer p-1 text-left ${
            this.props.fontClass
          } ${
            option === this.props.activeText
              ? `scroll-select__button--active-text`
              : ``
          }`}
          onClick={() => {
            if (this.props.onUpdate) {
              this.props.onUpdate(option, index);
            }
          }}
        >
          {option}
        </button>
      );
    });

    return jsx;
  };

  reset = () => {
    this.clearIntervals();

    this.setState({
      active: false
    });
  };

  //

  render() {
    return (
      <div
        ref={this.componentRef}
        role="presentation"
        className={`scroll-select relative block z-40 cursor-pointer p-1 ${
          this.props.className
        } ${this.state.active ? `active` : ``} ${
          this.props.interacted ? `interacted` : ``
        }`}
        onClick={() => {
          this.setState(prevState => ({
            active: !prevState.active
          }));
        }}
      >
        <h3
          ref={this.defaultSelectRef}
          className="scroll-select__placeholder p-1"
        >
          {this.props.activeText}
        </h3>

        <svg
          className="z-20"
          viewBox="0 0 17 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.85721 6.99382e-07L16.7752 12L0.939265 12L8.85721 6.99382e-07Z" />
        </svg>

        <div className="scroll-select__options w-full absolute top-0 left-0 right-0 z-10 p-1">
          <div className="scroll-select__overlay w-full relative z-50">
            {this.getOptionJSX()}
          </div>
        </div>
      </div>
    );
  }
}

ScrollSelectComponent.defaultProps = {
  className: ``,
  fontClass: ``,
  interacted: false,
  onUpdate: null
};

ScrollSelectComponent.propTypes = {
  activeText: PropTypes.string.isRequired,
  className: PropTypes.string,
  fontClass: PropTypes.string,
  interacted: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func
};

const ScrollSelect = props => {
  return <ScrollSelectComponent {...props} />;
};

export default ScrollSelect;
