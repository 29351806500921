/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Glide from "@glidejs/glide";

import { AppContext } from "~context/AppContext";

import Layout from "~components/Layout";
import ScrollSelect from "~components/ScrollSelect";
import SEO from "~components/SEO";

import { shuffleArray, toTitleCase } from "~utils/helpers";

import afterpay from "~assets/images/icons/afterpay.png";
import amex from "~assets/images/icons/amex.png";
import mastercard from "~assets/images/icons/mastercard.png";
import paypal from "~assets/images/icons/paypal.png";
import secure from "~assets/images/icons/secure.png";
import visa from "~assets/images/icons/visa.png";

class ShopifyProductComponent extends Component {
  state = {
    quantity: 1,
    select: {
      interacted: false,
      value: ``,
      variantId: 0,
      variantIndex: 0
    },
    threeCanvasReady: false
  };

  bannerRef = React.createRef();

  glide;

  carouselImagesByVariantId = {};

  relatedProducts = [];

  selectOptions = {
    name: ``,
    options: []
  };

  shopifyAdminProduct;

  componentDidMount() {
    const { appContext, pageContext } = this.props;
    const {
      allShopifyProduct,
      allMarkdownRemark,
      markdownRemark,
      shopifyProduct
    } = this.props.data;

    appContext.setNavText(`Back`);

    window.addEventListener(`scroll`, this.handleScroll, false);

    //
    // related products
    const { category } = markdownRemark.frontmatter;
    const allRelatedProducts = [];

    if (category && category !== ``) {
      allShopifyProduct.edges.forEach(({ node }) => {
        if (
          node.handle.toLowerCase().includes(`beanie`) ||
          node.handle === `sticky-pack`
        ) {
          return;
        }

        allMarkdownRemark.edges.forEach(edge => {
          const { frontmatter } = edge.node;

          if (frontmatter.handle === node.handle) {
            node.fm_title = frontmatter.title;
            node.discount = frontmatter.discount;
            node.preorder = frontmatter.preorder;
            node.soldOut = frontmatter.soldOut;
            node.featuredImage = frontmatter.featuredImage;
          }
        });

        if (node.featuredImage) {
          if (category === `Makeup` && !node.soldOut) {
            if (
              node.handle === `bronzing-powder` ||
              node.handle === `kabuki-brush` ||
              node.handle === `bronzing-duet` ||
              node.handle === `face-oil` ||
              node.handle === `lip-oil-1-clear` ||
              node.handle === `lip-oil-1-clear-refill` ||
              node.handle === `lip-oil-2-tint` ||
              node.handle === `lip-oil-2-tint-refill` ||
              node.handle === `the-foundation`
            ) {
              allRelatedProducts.push(node);
            }
          } else if (
            node.handle !== `bronzing-powder` &&
            node.handle !== `kabuki-brush` &&
            node.handle !== `bronzing-duet` &&
            node.handle !== `face-oil` &&
            node.handle !== `lip-oil-1-clear` &&
            node.handle !== `lip-oil-1-clear-refill` &&
            node.handle !== `lip-oil-2-tint` &&
            node.handle !== `lip-oil-2-tint-refill` &&
            node.handle !== `the-foundation`
          ) {
            allRelatedProducts.push(node);
          }
        }
      });
    }

    shuffleArray(allRelatedProducts);

    allRelatedProducts.forEach((relatedProduct, index) => {
      const relatedIndex = index;

      if (relatedIndex >= 3) {
        return;
      }

      this.relatedProducts.push(relatedProduct);
    });

    //
    // Select Options

    console.log(markdownRemark.frontmatter);

    if (pageContext.multiVariant) {
      const { select } = this.state;

      select.value = toTitleCase(
        shopifyProduct.variants[0].selectedOptions[0].value
      );

      this.setState({
        select
      });

      this.selectOptions.name =
        shopifyProduct.variants[0].selectedOptions[0].name;

      shopifyProduct.variants.forEach(variant => {
        variant.selectedOptions.forEach(option => {
          if (option.value && option.value !== ``) {
            this.selectOptions.options.push(toTitleCase(option.value));
          }
        });
      });
    }

    //
    // carousel

    if (markdownRemark.frontmatter.modelGallery) {
      this.glide = new Glide(`.glide`, this.glideOptions());

      this.glide.on(`mount.after`, () => {
        this.setState({
          threeCanvasReady: true
        });
      });

      this.glide.mount();
    }
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll, false);
    this.props.appContext.setHeaderCollapsed(false);
  }

  glideOptions = () => {
    return {
      autoplay: 2000,
      gap: 0,
      peek: 0,
      perView: 1,
      type: `slider`
    };
  };

  //

  handleSelect = (option, index) => {
    const { select } = this.state;

    select.interacted = true;
    select.variantIndex = index;
    select.value = option;

    this.glide.destroy();

    this.setState(
      {
        threeCanvasReady: false,
        select
      },
      () => {
        this.glide = new Glide(`.glide`, this.glideOptions());
        this.glide.on(`mount.after`, () => {
          this.setState({
            threeCanvasReady: true
          });
        });
        this.glide.mount();
      }
    );
  };

  handleScroll = e => {
    if (!this.bannerRef || !this.bannerRef.current) {
      return;
    }

    const { scrollTop } = e.target.scrollingElement;

    if (
      scrollTop >
      this.bannerRef.current.getBoundingClientRect().height + 48
    ) {
      this.props.appContext.setHeaderCollapsed(true);
    } else {
      this.props.appContext.setHeaderCollapsed(false);
    }
  };

  //

  addToCart = () => {
    const { cart } = this.props.appContext;
    const { shopifyProduct } = this.props.data;

    const selectedProduct = {
      handle: shopifyProduct.handle,
      image:
        shopifyProduct.variants[this.state.select.variantIndex].image
          .originalSrc,
      name: shopifyProduct.title,
      price: shopifyProduct.variants[this.state.select.variantIndex].price,
      quantity: 0,
      variantId: shopifyProduct.admin_variant_id
    };

    if (cart && cart.length) {
      let added = false;

      cart.forEach(cartItem => {
        if (added) {
          return;
        }

        if (cartItem.variantId === selectedProduct.variantId) {
          added = true;
          cartItem.quantity += this.state.quantity;
        }
      });

      if (!added) {
        selectedProduct.quantity = this.state.quantity;
        cart.push(selectedProduct);
      }
    } else {
      selectedProduct.quantity = this.state.quantity;
      cart.push(selectedProduct);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `Add Product`,
      price: selectedProduct.name
    });

    this.props.appContext.setCart(cart);
    this.props.appContext.setCartActive(true);
  };

  quantityDown = () => {
    if (this.state.quantity <= 1) {
      return;
    }

    this.setState(prevState => ({
      quantity: prevState.quantity - 1
    }));
  };

  quantityUp = () => {
    this.setState(prevState => ({
      quantity: prevState.quantity + 1
    }));
  };

  //

  render() {
    const { pageContext } = this.props;
    const { shopifyProduct, allShopifyAdminProduct } = this.props.data;
    const { frontmatter } = this.props.data.markdownRemark;

    let bannerImage = ``;

    if (
      frontmatter.bannerMedia &&
      frontmatter.bannerMedia.src &&
      frontmatter.bannerMedia.src.childImageSharp
    ) {
      bannerImage = frontmatter.bannerMedia.src.childImageSharp.fluid.src;
    }

    //

    if (shopifyProduct && shopifyProduct.handle && pageContext.inShopify) {
      allShopifyAdminProduct.edges.forEach(adminProduct => {
        if (adminProduct.node.products) {
          adminProduct.node.products.forEach(nestedProduct => {
            if (nestedProduct.handle === shopifyProduct.handle) {
              shopifyProduct.admin_variant_id =
                nestedProduct.variants[
                  this.state.select.variantIndex
                ].alternative_id;

              if (
                frontmatter.modelGallery &&
                frontmatter.modelGallery.length === 1
              ) {
                shopifyProduct.variantId = 0;
              } else {
                shopifyProduct.variantId =
                  nestedProduct.variants[
                    this.state.select.variantIndex
                  ].alternative_id;
              }
            }
          });
        }
      });
    }

    let cartTotal = 0;

    this.props.appContext.cart.forEach(cartItem => {
      cartTotal += parseInt(cartItem.price * cartItem.quantity);
    });

    // populates the carousel with correct imagery

    if (frontmatter.modelGallery) {
      frontmatter.modelGallery.forEach(modelGallery => {
        this.carouselImagesByVariantId[modelGallery.variantId] =
          modelGallery.images;
      });
    }

    return (
      <>
        <SEO pageTitle={frontmatter.title} pathName="/" />

        <Layout className="shopify-product relative gpu bg-sun-yellow">
          <section
            ref={this.bannerRef}
            className="shopify-product__banner animation-appear w-full relative flex items-center justify-center gpu overflow-hidden"
          >
            <div className="shopify-product__banner__carousel w-1/3 h-full relative">
              {this.props.appContext.device === `mobile` && (
                <div
                  className={`w-full relative px-4 pb-4 z-20 bg-white ${
                    cartTotal < 50 ? `pt-10` : `pt-4`
                  }`}
                >
                  {cartTotal < 50 && (
                    <Link
                      to="/products"
                      className="shopify-product__sticky__marquee w-full absolute left-0 top-0 right-0 flex items-center justify-start gpu cursor-pointer bg-sun-yellow whitespace-no-wrap"
                    >
                      <span className="marquee mr-8 b2 font-bold">
                        Free Shipping in Australia for orders over $50. Add more
                        Fluff or Stuff.
                      </span>
                      <span className="marquee mr-8 b2 font-bold">
                        Free Shipping in Australia for orders over $50. Add more
                        Fluff or Stuff.
                      </span>
                      <span className="marquee mr-8 b2 font-bold">
                        Free Shipping in Australia for orders over $50. Add more
                        Fluff or Stuff.
                      </span>
                      <span className="marquee mr-8 b2 font-bold">
                        Free Shipping in Australia for orders over $50. Add more
                        Fluff or Stuff.
                      </span>
                      <span className="marquee mr-8 b2 font-bold">
                        Free Shipping in Australia for orders over $50. Add more
                        Fluff or Stuff.
                      </span>
                    </Link>
                  )}

                  <div className="w-full flex items-start justify-between mb-3">
                    <div className="relative block">
                      <h3 className="f5 font-medium">{frontmatter.title}</h3>

                      <h4 className="mt-1 b3">
                        {(frontmatter.preorder && `*Ships Jan 31`) ||
                          frontmatter.category}
                      </h4>
                    </div>

                    <h3 className="flex items-start">
                      <span className="f5 font-medium">
                        $
                        {shopifyProduct
                          ? shopifyProduct.variants[
                              this.state.select.variantIndex
                            ].price
                          : `-`}
                      </span>
                      <span className="shopify-product__currency b3 font-light">
                        AUD
                      </span>
                    </h3>
                  </div>

                  <div className="w-full relative flex flex-col items-stretch justify-between bg-white">
                    {pageContext.multiVariant && (
                      <div className="w-full flex flex-row items-center my-4">
                        <h4 className="b1 font-medium mr-4">
                          {this.selectOptions.name}:
                        </h4>

                        <ScrollSelect
                          activeText={this.state.select.value}
                          className="shopify-product__select b1 font-medium w-full"
                          fontClass="b1 font-medium"
                          interacted={this.state.select.interacted}
                          onUpdate={this.handleSelect}
                          options={this.selectOptions.options}
                        />
                      </div>
                    )}

                    <div className="w-full flex flex-row">
                      <div className="relative flex flex-row flex-1 items-center justify-between pr-8">
                        <h4 className="b1 font-medium">Qty:</h4>

                        <button
                          type="button"
                          className="p-2 b1 font-medium"
                          onClick={this.quantityDown}
                        >
                          -
                        </button>
                        <h4
                          className="b1 text-center font-medium"
                          style={{ width: 24 }}
                        >
                          {this.state.quantity}
                        </h4>
                        <button
                          type="button"
                          className="p-2 b1 font-medium"
                          onClick={this.quantityUp}
                        >
                          +
                        </button>
                      </div>

                      {!pageContext.inShopify ? (
                        <button
                          type="button"
                          className="shopify-product__add relative block px-5 py-4 f4 bg-black font-medium text-sun-yellow pointer-events-none"
                        >
                          Coming Soon
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={`shopify-product__add relative block px-5 py-4 f4 bg-black font-medium text-sun-yellow ${
                            frontmatter.soldOut ? `pointer-events-none` : ``
                          }`}
                          onClick={this.addToCart}
                        >
                          {frontmatter.soldOut
                            ? `Sold Out`
                            : (frontmatter.preorder && `Pre Order`) ||
                              `Buy Now`}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {frontmatter.modelGallery && (
                <div
                  className={`glide w-full h-full relative ${
                    this.state.threeCanvasReady ? `` : `opacity-0`
                  }`}
                >
                  <div
                    className="glide__track w-full h-full relative"
                    data-glide-el="track"
                  >
                    <ul className="glide__slides w-full h-full relative items-stretch">
                      {this.carouselImagesByVariantId[
                        frontmatter.modelGallery[this.state.select.variantIndex]
                          .variantId
                      ] &&
                        this.carouselImagesByVariantId[
                          frontmatter.modelGallery[
                            this.state.select.variantIndex
                          ].variantId
                        ].map((image, index) => {
                          const galleryItemIndex = index;

                          return (
                            <li
                              key={`${frontmatter.title}-gallery-${galleryItemIndex}`}
                              className="shopify-product__banner__carousel-image w-full relative flex items-center justify-center overflow-hidden"
                            >
                              <Img
                                style={{ width: `100%`, height: `100%` }}
                                fluid={image.childImageSharp.fluid}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </div>

                  <div className="w-full absolute right-0 bottom-0 left-0 pb-4 bordered pointer-events-none">
                    <ul
                      className="glide__bullets flex items-center justify-center"
                      data-glide-el="controls[nav]"
                    >
                      {this.carouselImagesByVariantId[
                        frontmatter.modelGallery[this.state.select.variantIndex]
                          .variantId
                      ] &&
                        this.carouselImagesByVariantId[
                          frontmatter.modelGallery[
                            this.state.select.variantIndex
                          ].variantId
                        ].map((galleryItem, index) => {
                          const buttonIndex = index;

                          return (
                            <li key={`carousel-bullet-${buttonIndex}`}>
                              <button
                                type="button"
                                className="glide__bullet mx-1 bg-black rounded-full"
                                data-glide-dir={`=${buttonIndex}`}
                              >
                                <span>{buttonIndex}</span>
                              </button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="shopify-product__banner__media w-2/3 h-full relative">
              <article
                className="shopify-product__banner__media__inner w-full h-full absolute top-0 right-0 bottom-0 left-0 py-8 px-10"
                style={{
                  backgroundImage: `url(${bannerImage})`
                }}
              >
                <h1 className="relative z-10 pb-12">
                  <span className="shopify-product__title f1 font-medium text-sun-yellow">
                    {frontmatter.title}
                  </span>
                  {frontmatter.number && (
                    <>
                      <br />
                      <span className="f1 font-medium text-sun-yellow">
                        {frontmatter.number}
                      </span>
                    </>
                  )}
                </h1>

                <p className="shopify-product__tagline relative z-10 mt-6 pr-8 f2 font-medium text-sun-yellow">
                  {frontmatter.description}
                </p>
              </article>
            </div>
          </section>

          {this.props.appContext.device !== `mobile` && (
            <section
              className={`shopify-product__sticky w-full sticky right-0 left-0 flex items-center pb-2 z-20 bg-white ${
                cartTotal < 50 ? `pt-16` : `pt-2`
              }`}
            >
              {cartTotal < 50 && (
                <Link
                  to="/products"
                  className="shopify-product__sticky__marquee animation-appear-from-right w-full absolute top-0 right-0 left-0 flex items-center justify-start gpu py-6 bg-sun-yellow whitespace-no-wrap"
                  style={{ animationDelay: `100ms` }}
                >
                  <span className="marquee mr-8 b1 font-bold">
                    Free Shipping in Australia for orders over $50. Add more
                    Fluff or Stuff.
                  </span>
                  <span className="marquee mr-8 b1 font-bold">
                    Free Shipping in Australia for orders over $50. Add more
                    Fluff or Stuff.
                  </span>
                  <span className="marquee mr-8 b1 font-bold">
                    Free Shipping in Australia for orders over $50. Add more
                    Fluff or Stuff.
                  </span>
                  <span className="marquee mr-8 b1 font-bold">
                    Free Shipping in Australia for orders over $50. Add more
                    Fluff or Stuff.
                  </span>
                  <span className="marquee mr-8 b1 font-bold">
                    Free Shipping in Australia for orders over $50. Add more
                    Fluff or Stuff.
                  </span>
                </Link>
              )}

              <div
                className="animation-appear-from-left w-1/3 relative block mb-4 pl-6"
                style={{ animationDelay: `200ms` }}
              >
                <h3 className="f5 font-medium">{frontmatter.title}</h3>

                <h4 className="mt-1 b3">
                  {(frontmatter.preorder && `*Ships Jan 31`) ||
                    frontmatter.category}
                </h4>
              </div>

              <div
                className="animation-appear-from-left w-1/3 relative flex flex-col items-start justify-between mb-4"
                style={{ animationDelay: `300ms` }}
              >
                <div className="w-full flex flex-row items-center justify-between">
                  <div className="relative flex items-center">
                    <h4 className="b1 font-medium">Qty:</h4>

                    <button
                      type="button"
                      className="p-4 b1 font-medium"
                      onClick={this.quantityDown}
                    >
                      -
                    </button>
                    <h4
                      className="b1 text-center font-medium"
                      style={{ width: 36 }}
                    >
                      {this.state.quantity}
                    </h4>
                    <button
                      type="button"
                      className="p-4 b1 font-medium"
                      onClick={this.quantityUp}
                    >
                      +
                    </button>
                  </div>

                  <h3 className="w-1/2 flex items-start justify-end f5 font-medium">
                    <span>
                      $
                      {shopifyProduct
                        ? (
                            shopifyProduct.variants[
                              this.state.select.variantIndex
                            ].price * this.state.quantity
                          ).toFixed(2)
                        : `-`}
                    </span>

                    <span className="shopify-product__currency b3 font-light">
                      AUD
                    </span>
                  </h3>
                </div>

                {pageContext.multiVariant && (
                  <div className="w-full flex flex-row items-center">
                    <h4 className="b1 font-medium mr-4">
                      {this.selectOptions.name}:
                    </h4>

                    <ScrollSelect
                      activeText={this.state.select.value}
                      className="shopify-product__select b1 font-medium w-1/3"
                      fontClass="b1 font-medium"
                      interacted={this.state.select.interacted}
                      onUpdate={this.handleSelect}
                      options={this.selectOptions.options}
                    />
                  </div>
                )}
              </div>

              <div
                className="animation-appear-from-left w-1/3 relative flex flex-col items-center justify-center pr-6"
                style={{ animationDelay: `400ms` }}
              >
                {!pageContext.inShopify ? (
                  <button
                    type="button"
                    className="shopify-product__add w-full relative block py-3 mb-2 b1 bg-black font-medium text-sun-yellow pointer-events-none"
                    onClick={this.addToCart}
                  >
                    Coming Soon
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`shopify-product__add w-full relative block py-3 mb-2 b1 bg-black font-medium text-sun-yellow ${
                      frontmatter.soldOut ? `pointer-events-none` : ``
                    }`}
                    onClick={this.addToCart}
                  >
                    {frontmatter.soldOut
                      ? `Sold Out`
                      : (frontmatter.preorder && `Pre Order`) || `Buy Now`}
                  </button>
                )}

                <div className="w-full relative flex items-center justify-center">
                  <h4
                    className="mr-6 b3"
                    style={{ fontSize: `9px`, lineHeight: `1px` }}
                  >
                    Secure Payment
                  </h4>

                  <img
                    className="shopify-product__payment-icon relative block mx-1"
                    src={secure}
                    alt="secure"
                  />
                  <img
                    className="shopify-product__payment-icon relative block mx-1"
                    src={visa}
                    alt="visa"
                  />
                  <img
                    className="shopify-product__payment-icon relative block mx-1"
                    src={mastercard}
                    alt="mastercard"
                  />
                  <img
                    className="shopify-product__payment-icon relative block mx-1"
                    src={amex}
                    alt="amex"
                  />
                  <img
                    className="shopify-product__payment-icon relative block mx-1"
                    src={paypal}
                    alt="paypal"
                  />
                  <img
                    className="shopify-product__payment-icon shopify-product__payment-icon--small relative block mx-1"
                    src={afterpay}
                    alt="afterpay"
                  />
                </div>
              </div>
            </section>
          )}

          <section className="shopify-product__headline w-full relative flex items-start py-12 bg-off-white">
            <div
              className="shopify-product__headline__inner animation-appear w-2/3 relative pl-6 p-4"
              style={{ animationDelay: `500ms` }}
            >
              <h3 className="f3 font-medium">
                <span>{frontmatter.headline}</span>
              </h3>

              <div className="shopify-product__headline__meta w-full flex items-start justify-between mt-16">
                <p className="shopify-product__headline__yn w-1/2 pr-4 b1 font-medium">
                  {frontmatter.headlineYes}
                </p>

                <p className="shopify-product__headline__yn w-1/2 pl-4 b1 font-medium">
                  {frontmatter.headlineNo}
                </p>
              </div>
            </div>

            <div
              className="shopify-product__headline__inner animation-appear w-1/3 relative pr-6 p-4"
              style={{ animationDelay: `500ms` }}
            >
              {frontmatter.ingredients && (
                <>
                  <h3 className="b2 font-medium">Ingredients:</h3>
                  <p className="mt-2 b2 font-light">
                    {frontmatter.ingredients}
                  </p>
                </>
              )}

              {frontmatter.shipping && (
                <>
                  <h3 className="mt-12 b2 font-medium">
                    Shipping &amp; Returns:
                  </h3>
                  <p className="mt-2 b2 font-light">{frontmatter.shipping}</p>
                </>
              )}
            </div>
          </section>

          {frontmatter.headlineMedia && (
            <section className="w-full relative flex items-center justify-center pt-8 bg-white">
              <Img
                className="w-3/4 relative"
                fluid={frontmatter.headlineMedia.childImageSharp.fluid}
              />
            </section>
          )}

          {/* {frontmatter.fullWidthMedia && (
            <section className="shopify-product__full-width relative pt-16 pb-32 px-6 bg-white">
              {(frontmatter.fullWidthMedia.type === `image` && (
                <Img
                  style={{
                    width: `100%`,
                    position: `relative`
                  }}
                  fluid={frontmatter.fullWidthMedia.src.childImageSharp.fluid}
                />
              )) || (
                <video
                  className="w-full relative"
                  src={frontmatter.fullWidthMedia.url}
                  alt={frontmatter.title}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              )}
            </section>
          )} */}

          {frontmatter.skinGuide && (
            <section className="shopify-product__skin-guide relative pb-32 px-6 bg-off-white">
              <h3 className="py-8 f2 text-black font-medium">
                Works on all skin tones
              </h3>

              <div className="shopify-product__skin-guide__flex w-full relative flex items-stretch justify-center">
                <div className="shopify-product__skin-guide__flex-item w-1/3 relative pr-4">
                  {frontmatter.skinGuide.imageLeft && (
                    <Img
                      style={{
                        width: `100%`,
                        position: `relative`
                      }}
                      fluid={
                        frontmatter.skinGuide.imageLeft.childImageSharp.fluid
                      }
                    />
                  )}

                  {frontmatter.skinGuide.textLeft && (
                    <p className="mt-6 b1">
                      <span className="mr-2 font-medium">
                        {frontmatter.skinGuide.headingLeft}
                      </span>
                      <span className="font-light">
                        {frontmatter.skinGuide.textLeft}
                      </span>
                    </p>
                  )}
                </div>

                <div className="shopify-product__skin-guide__flex-item w-1/3 relative px-4">
                  {frontmatter.skinGuide.imageCenter && (
                    <Img
                      style={{
                        width: `100%`,
                        position: `relative`
                      }}
                      fluid={
                        frontmatter.skinGuide.imageCenter.childImageSharp.fluid
                      }
                    />
                  )}

                  {frontmatter.skinGuide.textCenter && (
                    <p className="mt-6 b1">
                      <span className="mr-2 font-medium">
                        {frontmatter.skinGuide.headingCenter}
                      </span>
                      <span className="font-light">
                        {frontmatter.skinGuide.textCenter}
                      </span>
                    </p>
                  )}
                </div>

                <div className="shopify-product__skin-guide__flex-item w-1/3 relative pl-4">
                  {frontmatter.skinGuide.imageRight && (
                    <Img
                      style={{
                        width: `100%`,
                        position: `relative`
                      }}
                      fluid={
                        frontmatter.skinGuide.imageRight.childImageSharp.fluid
                      }
                    />
                  )}

                  {frontmatter.skinGuide.textRight && (
                    <p className="mt-6 b1">
                      <span className="mr-2 font-medium">
                        {frontmatter.skinGuide.headingRight}
                      </span>
                      <span className="font-light">
                        {frontmatter.skinGuide.textRight}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </section>
          )}

          {frontmatter.compliments && frontmatter.compliments.length > 0 && (
            <section className="relative pt-4 pb-32 px-6 bg-sun-yellow">
              <h3 className="f2 text-black font-medium">Compliments</h3>

              <div className="w-full flex items-center justify-center xs:block mt-16">
                {frontmatter.compliments.map((compliment, index) => {
                  const complimentIndex = index;

                  if (
                    complimentIndex > 2 ||
                    !compliment.src ||
                    !compliment.src.childImageSharp
                  ) {
                    return [];
                  }

                  return (
                    <div
                      key={`compliment-${complimentIndex}`}
                      className="w-1/4 xs:w-full relative block mx-6 xs:my-16 xs:mx-0"
                    >
                      <Img fluid={compliment.src.childImageSharp.fluid} />
                    </div>
                  );
                })}
              </div>
            </section>
          )}

          {/* {frontmatter.instagramEmbeds &&
            frontmatter.instagramEmbeds.length > 0 && (
              <section className="w-full relative py-16 px-12 bg-navy">
                <ul className="w-full relative flex items-start justify-center">
                  {frontmatter.instagramEmbeds.map((embed, index) => {
                    const embedIndex = index;

                    return (
                      <InstagramEmbed
                        key={`instagram-${embedIndex}`}
                        className="w-1/3 relative px-6"
                        url={embed.embed}
                      />
                    );
                  })}
                </ul>
              </section>
            )} */}

          {this.relatedProducts && this.relatedProducts.length > 0 && (
            <section className="products-grid__scroll products-grid__scroll--scroll w-full relative pt-16 pb-32 px-6 bg-off-white">
              <h3 className="products-grid__scroll__heading py-8 f2 text-black font-medium">
                Shop all Products
              </h3>

              <div className="products-grid__scroll__flex flex flex-row flex-wrap items-stretch justify-between">
                {this.relatedProducts.map((product, index) => {
                  const relatedProductIndex = index;

                  return (
                    <article
                      key={`related-product-${relatedProductIndex}`}
                      className="products-grid__product w-1/3 relative flex flex-col justify-between mb-12 px-6"
                    >
                      {product.discount > 0 && (
                        <div className="products-grid__product__discount absolute top-0 right-0 z-10 px-12 py-3 bg-periwinkle b2 font-medium uppercase text-white">
                          SAVE ${product.discount}
                        </div>
                      )}

                      <Link to={`/products/${product.handle}`}>
                        <div className="products-grid__product__image w-full relative overflow-hidden">
                          <Img
                            className="products-grid__product__image--gatsby"
                            fluid={product.featuredImage.childImageSharp.fluid}
                          />
                        </div>

                        <h3 className="my-2 f4 font-medium text-black">
                          {product.title}
                        </h3>
                      </Link>

                      <Link to={`/products/${product.handle}`}>
                        <button
                          type="button"
                          className="w-full relative block mt-3 py-3 f4 bg-sun-yellow hover:bg-black font-bold text-black hover:text-sun-yellow"
                        >
                          {product.preorder
                            ? `Pre-order: $${product.variants[0].price}`
                            : `Buy Now: $${product.variants[0].price}`}
                        </button>
                      </Link>
                    </article>
                  );
                })}
              </div>

              <div className="w-full relative flex items-center justify-center ">
                <Link to="/products" className="shopify-product__all block">
                  <button
                    type="button"
                    className="mt-3 py-3 px-6 bg-white b2 uppercase text-black"
                  >
                    Shop All
                  </button>
                </Link>
              </div>
            </section>
          )}
        </Layout>
      </>
    );
  }
}

const ShopifyProduct = props => {
  const { data, pageContext } = props;

  return (
    <AppContext.Consumer>
      {appContext => (
        <ShopifyProductComponent
          appContext={appContext}
          data={data}
          pageContext={pageContext}
        />
      )}
    </AppContext.Consumer>
  );
};

export default ShopifyProduct;

export const query = graphql`
  query($id: String!, $handle: String) {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            handle
            discount
            preorder
            soldOut
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1080) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  src
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        handle
        title
        number
        modelGallery {
          variantId
          images {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        bannerMedia {
          src {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          type
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              src
            }
          }
        }
        hasModel
        preorder
        description
        category
        headline
        headlineYes
        headlineNo
        ingredients
        shipping
        soldOut
        fullWidthMedia {
          src
          type
          url
        }
        skinGuide {
          imageCenter {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageLeft {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageRight {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          headingCenter
          headingLeft
          headingRight
          textCenter
          textLeft
          textRight
        }
        instagramEmbeds {
          embed
        }
        compliments {
          src {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      description
      handle
      images {
        originalSrc
      }
      productType
      vendor
      variants {
        id
        title
        image {
          originalSrc
        }
        price
        selectedOptions {
          name
          value
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          variants {
            price
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
